@import url('https://fonts.cdnfonts.com/css/snore-metal');

/* Page Header */
.page-header {
  background-color: inherit;
  text-align: center;
}

.logo {
  width: 50%;
  padding: 0px;
  margin: 0px;
}

.logo-area-image {
  margin: 1rem;
  width: fit-content;
  background-color: inherit;
}

/* Nav main */

.nav-dropdown-menu {
  position: absolute;
  display: flex;
  width: 100vw;
  height: auto;
  right: 0px;
  background-color: green;
  border: 1px solid red;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 13.2vh;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.nav-main {
  width: 100vw;
  height: fit-content;
  border-bottom: 0.2rem rgb(120, 18, 18) inset;
  padding-bottom: 0.5rem;
  background: rgba(22, 130, 22, 0.368);
  overflow: hidden;
}

.desktop-nav {
  width: 100vw;
  display: flex;
  justify-content: space-around;
}

.mobile-nav {
  display: none;
}

.nav-button {
  position: relative; 
  display: inline-block;
  width: 16vw;
  height: 12vh;
  background-color: rgba(255, 251, 251, 0);
  padding: 0.5rem;
  border: none;
  color: white;
  font-size: 1.75rem;
  font-family: 'SNORE METAL', sans-serif;
  font-stretch: ultra-expanded;
}

.selected-nav-button {
  filter: hue-rotate(180deg);
}

.nav-button-mobile {
  width: 20vw;
}

.nav-button-boarder{
  width: 100%; 
  height: 100%; 
  position: absolute; 
  top: 0;
  left: 0;
  z-index: 0;
}

.nav-button-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
  z-index: 1;
  margin: 0;
}

/* Image Displayer */

.row {
  display: flex;
  margin-left: 2rem;
  min-width: 85vw;
}
.row-1 {
  gap: 4rem;
  margin-left: 1rem;
}
.row-2 {
  gap: 1rem;
  margin-left: 1rem;
}
.row-3 {
  gap: 2rem;
  margin-left: 1rem;
}
.row-4 {
  gap: 0.5rem;
  margin-left: 1rem;
}
.row-5 {
  gap: 0.5rem;
  margin-left: 1rem;
}
.row-6 {
  gap: 0.5rem;
  margin-left: 1rem;
}
.row-7 {
  display: flex;
  gap: 0.5rem;
  margin-left: 1rem;
}

.image-displayer {
  min-height: 100vh;
}

.image-container-container {
  max-width: 100vw;
  display: block;
  min-width: 95vw;
  margin-top: 3rem;
  margin-left: 20vw;
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 13vw;
  height: 12vw;
  margin: 0.5rem;
}

.pagination {
  display: flex;
  justify-content: center;
}

.page-button {
  margin: 1rem;
}

.image-container img {
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  border-radius: 5px;
}
/* Page footer */

.page-footer {
  background-color: #1a1a1a;
  width: 100vw;
  color: #b0b0b0;
  text-align: center;
  margin: 0px;
  padding: 20px;
  margin-top: 40px;
}

@media (max-width: 768px) {
  .image-container-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .nav-dropdown-menu {
    left: 0px;
  }
  .desktop-nav {
    display: none;
  }
  .mobile-nav {
    display: flex;
    justify-content: center;
  }
  .nav-button {
    width: 26vw;
  }
  .nav-button-mobile {
    width: 30vw;
    height: 13vh;
  }
}

@media (max-width: 480px) {
  .image-container-container {
    grid-template-columns: 1fr;
  }
}
